
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { DataType } from "@/services/file";
import { AxiosResponse } from "axios";
import { IFileListItem } from "@/models/file";
import $API from "@/services";
import FileList from "@/components/file/FileList.vue";
import FileViewer from "@/components/file/FileViewer.vue";
import { useStore } from "@/store";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import { RouterName } from "@/models/common";
import Card from "primevue/card";
import router from "@/router";

export default defineComponent({
  name: "File Manager",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const key = ref(props.id);

    const isPX = computed(() => {
      return router.currentRoute.value.name === RouterName.FILE_MANAGER_PX;
    });

    const state = reactive({
      isLoading: true,
      select: null as IFileListItem | null,
      list: [] as Array<IFileListItem>,
      isPX,
    });

    const select = computed({
      set: (val) => {
        state.select = val;
      },
      get: () => {
        return state.select;
      },
    });

    const onSetData = async () => {
      try {
        state.isLoading = true;
        store.commit(CommonMutationTypes.SET_IS_LODING, true);

        const ResultData = await $API.FileService.getFile(key.value);

        if (!Array.isArray(ResultData)) return;
        else if (!isPX.value)
          state.list = ResultData.filter(
            (file) =>
              (file.PrescriptionFlag?.valueOf() ?? 0) == 0 &&
              file.FileStatus == 0
          );
        else if (isPX.value) {
          // 의사가 올린 처방전만 보여주기
          // state.list = ResultData.filter((x) => {
          //   if (x.RefKeyValue3) return x;
          // });

          state.list = ResultData;

          const pxFile = state.list.filter(
            (file) =>
              (file.PrescriptionFlag?.valueOf() ?? 0) == 1 &&
              file.FileStatus == 0
          );
          if (pxFile.length > 0) state.select = pxFile[0];
        }
      } catch (e) {
        console.error(e);
      } finally {
        // state.isLoading = false;
        store.commit(CommonMutationTypes.SET_IS_LODING, false);
        state.isLoading = false;
      }
    };

    onMounted(async () => {
      await onSetData();
    });

    return { key, state, DataType, select, onSetData };
  },
  methods: {
    async deletedFile() {
      await this.onSetData();
    },
    async onSelectedRow(selectedItem: IFileListItem) {
      try {
        this.select = selectedItem;
      } catch (e) {
        console.error(e);
      }
    },
    async getStudyKey(fileKey: string) {
      try {
        const res: AxiosResponse = await $API.FileService.getStudyKey(fileKey);
        console.log(res);
      } catch (e) {
        console.error(e);
      }
    },
    getImagePath() {
      if (this.state.select)
        return (
          `/${process.env.VUE_APP_SUB_URL}` +
          "/Uploads/" +
          this.state.select.FilePath
        );
    },
  },
  components: {
    FileList,
    FileViewer,
    Card,
  },
});
